
// Vue reactivity
import { defineComponent, reactive, computed, ref, } from 'vue';

// icons
import { arrowBack, arrowBackOutline, arrowForward, close, } from 'ionicons/icons';

// components
import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation }from 'swiper';
import { IonicSwiper, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonItem, IonLabel,
        IonContent, IonGrid, IonRow, IonCol, IonSpinner, IonRadio, IonRadioGroup,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, 
        modalController, alertController, } from '@ionic/vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import DesignService from '@/services/DesignService';

import config from '@/config';
import { utils } from '@/composables/utils';

SwiperCore.use([IonicSwiper, Navigation]);

export default defineComponent({
  name: 'BabeOrderModal',
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonItem, IonLabel,
                IonContent, IonGrid, IonRow, IonCol, IonSpinner, IonRadio, IonRadioGroup,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
                Swiper, SwiperSlide },
  setup(props) {
    const PRODUCT_NAME = "寵物芭比娃娃";

    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { openImageModal, closeModal, getHTMLImg, releaseHTMLCanvases, sleep, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loadingData = computed(() => store.state.loadingPublicData);
    const settings = computed(() => store.state.settings);
    const apparels = computed(() => store.getters.petBabeApparels);

    const selectedSet = reactive({
      upper: null,
      shoe: null,
      previewImage: null,
    });

    const updateSelectedSetPreviewImage = async () => {
      const [img1, img2]: any = await Promise.all([
        getHTMLImg(selectedSet.upper ? selectedSet.upper.photoLink : ""),
        getHTMLImg(selectedSet.shoe ? selectedSet.shoe.photoLink : "")
      ]);
      await sleep(0.5);
      const canvasEl = document.createElement("canvas");
      const ctx = canvasEl.getContext("2d");
      ctx.fillStyle = "#fff"; // white background
      canvasEl.width = Math.max(img1.width, img2.width);
      img1.height = img1.height * (canvasEl.width / img1.width);
      img2.height = img2.height * (canvasEl.width / img2.width);
      canvasEl.height = img1.height + img2.height;
      ctx.drawImage(img1, 0, 0, canvasEl.width, img1.height);
      ctx.drawImage(img2, 0, img1.height, canvasEl.width, img2.height);
      selectedSet.previewImage = canvasEl.toDataURL('image/jpeg'); // combined photos
      releaseHTMLCanvases([canvasEl]); // clean-up
    }

    const getOrderItemTotal = () => {
      const babeBottle = store.state.bottles.find(b => b.id == PRODUCT_NAME);
      return babeBottle ? babeBottle['1隻寵物售價'] : 1588;
    }


    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, arrowForward, close,

      // variables
      PRODUCT_NAME,
      loadingData, settings, apparels, selectedSet,
      swiperOps: {
        breakpoints: {
          // when window width is >= 640px
          768: {
            slidesPerView: 3.5,
          },
          1024: {
            slidesPerView: 4.5,
          }
        }
      },

      // methods
      t, closeModal, openImageModal,
      getOrderItemTotal,
      selectApparel: (targetKey, value) => {
        //if (selectedSet[targetKey]) selectedSet[targetKey] = null;
        selectedSet[targetKey] = value;
        updateSelectedSetPreviewImage();
      }
    }
  },
})
