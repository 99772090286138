
// Vue reactivity
import { computed } from 'vue';

// icons
import { enterOutline, refresh, } from 'ionicons/icons';

// Components
import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { EffectCube, EffectFlip, Navigation, Pagination, Autoplay }from 'swiper';
import { IonicSwiper, IonCard, IonCardHeader, IonCardSubtitle, IonButton, IonIcon,
        modalController,  } from '@ionic/vue';
import DesignPreQuestionModal from '@/components/DesignPreQuestionModal.vue';
import BabeOrderModal from '@/components/BabeOrderModal.vue';

import config from '@/config';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

SwiperCore.use([IonicSwiper, EffectCube, EffectFlip, Navigation, Pagination, Autoplay]);

export default {
  props: ['hidePagination', 'banners', 'photoLinks', 'homeBanners'],
  components: { Swiper, SwiperSlide, IonCard, IonCardHeader, IonCardSubtitle, IonButton, IonIcon, },
  setup() {
    const { openModal, openImageModal, } = utils();
    const store = useStore();
    const newDesign = computed(() => store.state.newDesign);
    const router = useRouter();

    const bannerOps = {
      speed: config.HomePage.banner.slidingSpeed,
      autoplay: {
        delay: config.HomePage.banner.autoPlayDelay,
      },
      breakpoints: {
        // when window width is >= 640px
        1024: {
          slidesPerView: 4.5,
        },
      },
    };

    const openDesignPreQuestionModal = async (clearNewDesign = false) => {
      if (clearNewDesign) {
        store.commit('resetNewDesign');
        localStorage.removeItem(config.newDesignLocalStorageKey);
      }
      if (!clearNewDesign && (newDesign.value.bottle && newDesign.value.bottle.id)) {
        router.replace(newDesign.value.id ? `/designs/${newDesign.value.id}` : '/new-design');
      } else {
        openModal(DesignPreQuestionModal, { designId: null });
      }
    }

    const openBabeOrderModal = async () => (await openModal(BabeOrderModal, {}));
    
    return {
      // icons
      enterOutline, refresh,

      // variables
      newDesign, bannerOps,

      // methods
      openImageModal, openDesignPreQuestionModal, openBabeOrderModal,
    }
  }
}
